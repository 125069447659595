<template>
  <section>
    <form class="columns is-multiline" @submit="onNextStep">
      <div class="column is-12">
        <div class="box">
          <div class="columns is-multiline">
            <div class="column is-12 is-paddingless">
              <h1>Portada</h1>
              <p>(Utiliza un fondo en blanco)</p>
            </div>
            <div class="column is-3">
              <template v-if="main">
                <div class="column has-text-right">
                  <i
                    class="material-icons icon"
                    @click="deleteImage('main')"
                  >
                    delete
                  </i>
                </div>
                <div :style="`background-image: url('${main.fileUrl}')`" class="productImage"></div>
              </template>
              <template v-else>
                <div class="card">
                  <div class="card-content">
                    <div class="file" :class="isErrorMain ? 'is-danger' : ''">
                      <label class="file-label">
                        <input
                          class="file-input"
                          type="file"
                          name="main"
                          @change="uploadImage"
                        >
                        <span class="file-cta">
                          <span class="file-icon">
                            <i class="material-icons icon">upload</i>
                          </span>
                          <span class="file-label">
                            Seleccionar
                          </span>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <div class="column is-12 is-paddingless">
              <h1>Fotos</h1>
              <p>Sube otras fotos del producto para que puedan conocerlo mejor</p>
            </div>
            <template v-for="(image, index) of images">
              <div class="column is-3" :key="index">
                <template v-if="image.fileUrl">
                  <div class="column has-text-right" :key="index">
                    <i
                      class="material-icons icon"
                      @click="deleteImage(index)"
                    >
                      delete
                    </i>
                  </div>
                  <div :style="`background-image: url('${image.fileUrl}')`" class="productImage"></div>
                </template>
                <template v-else>
                  <div class="card">
                    <div class="card-content">
                      <div class="file">
                        <label class="file-label">
                          <input
                            class="file-input"
                            type="file"
                            :name="index"
                            @change="uploadImage"
                          >
                          <span class="file-cta">
                            <span class="file-icon">
                              <i class="material-icons icon">upload</i>
                            </span>
                            <span class="file-label">
                              Seleccionar
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="column is-12">
        <div class="columns is-multiline is-mobile">
          <div class="column is-6 has-text-left">
            <button
              class="button is-link is-light"
              type="button"
              @click="onStepBack"
            >
              Regresar
            </button>
          </div>
          <div class="column is-6 has-text-right">
            <button
              class="button button-givu"
              :class="{ 'is-loading': isLoadingSuccess}"
              type="submit"
            >
              Finalizar
            </button>
          </div>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'

export default {
  name: 'Photos',
  data () {
    return {
      main: null,
      images: [
        { fileName: null, fileUrl: null, file: null },
        { fileName: null, fileUrl: null, file: null },
        { fileName: null, fileUrl: null, file: null },
        { fileName: null, fileUrl: null, file: null }
      ],
      isErrorMain: false,
      isLoadingSuccess: false
    }
  },
  methods: {
    ...mapMutations(['updateStepNewProduct', 'setNewProductValues']),
    ...mapActions(['addProduct']),
    uploadImage (event) {
      this.errorImage = []
      const [ file ] = event.target.files
      const reader = new FileReader()
      reader.onload = (e) => {
        var img = new Image()
        img.onload = function () {
        }
        img.src = reader.result
      }
      reader.readAsDataURL(file)
      const localUrl = URL.createObjectURL(file)
      let data = {
        fileName: file.name,
        fileUrl: localUrl,
        file
      }
      if (event.target.name === 'main') {
        this.main = data
        this.isErrorMain = false
      } else {
        let images = [ ...this.images ]
        images[event.target.name] = data
        this.images = images
      }
    },
    deleteImage (name) {
      if (name === 'main') this.main = null
      else {
        let images = [ ...this.images ]
        images[name] = { fileName: null, fileUrl: null, file: null }
        this.images = images
      }
    },
    onStepBack () {
      this.updateStepNewProduct(2)
    },
    async onNextStep (e) {
      e.preventDefault()
      this.isLoadingSuccess = true
      if (!this.main) {
        this.isErrorMain = true
        return true
      }
      let validateImages = this.images.reduce((accum, item) => {
        if (item.fileName) accum.push(item)
        return accum
      }, [])
      let getImages = []
      getImages.push(this.main)
      if (validateImages.length) getImages = [...getImages, ...validateImages]
      await this.setNewProductValues({
        images: getImages
      })
      let isSuccess = await this.addProduct()
      this.isLoadingSuccess = false
      if (isSuccess) this.$router.push({ name: 'inventario' })
    }
  }
}
</script>

<style scoped lang="scss">
  .productImage {
    width: 100%;
    height: 150px;
    border-radius: 5px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    transition: all 300ms;
    &:hover {
      transform: scale(1.09);
    }
  }
  .icon {
    cursor: pointer;
  }
</style>
